$(function () {
  /*  ============================================================================
      Scrolling Navbar JS
      ========================================================================= */
  $(document).scroll(function () {
    var $nav = $('.navbar');
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
  });

  /*  ============================================================================
      Close collapse menu on nav click
      ========================================================================= */
  $('.navbar-nav a').on('click', function () {
    $('.navbar-toggler').click(); //bootstrap 4.x
  });

  /*  ============================================================================
      GSAP JS
      ========================================================================= */
  // ------------------------------------------------------------- setup variables

  /*  ============================================================================
      ScrollMagic JS
      ========================================================================= */
  // ------------------------------------------------------------- init ScrollMagic or tell browser to utilize scrollbar for navigation
  var controller = new ScrollMagic.Controller();
  /* Chamber Champions Alert Scene
   *******************************/
  // var champion = new ScrollMagic.Scene({
  //   triggerElement: '.about',
  //   duration: '60%',
  //   triggerHook: 0
  // })
  // .setClassToggle('#championAlert', 'fade-in')
  // .addIndicators({
  //   name: 'Champion Scene',
  //   colorTrigger: 'white',
  //   colorStart: 'red',
  //   colorEnd: 'orange'
  // })
  // .addTo(controller);

  /* Spacer Parallax Scene
   ***********************/
  var parallaxTl = new TimelineMax();
  parallaxTl
    .from(
      '.spacer__content',
      0.1,
      {
        // autoAlpha: 0,
        ease: Power0.easeNone,
      },
      0.7
    )
    .from(
      '.spacer__bg',
      2,
      {
        y: '-20%', // always should be same size as the starting element which is set to height 150%
        ease: Power0.easeNone,
      },
      0
    ); // the comma then 0 makes this animation start at absolute 0 position so both the text and parallax happen at the same time

  var spacerParallaxScene = new ScrollMagic.Scene({
    triggerElement: '.spacer',
    duration: '200%',
    triggerHook: 1,
  })
    .setTween(parallaxTl)
    // .addIndicators({
    //   name: 'spacer parallax',
    //   colorTrigger: 'pink',
    //   colorStart: 'yellow',
    //   colorEnd: 'orange'
    // })
    .addTo(controller);

  // loop through each animation element
  $('.animation').each(function () {
    // console.log(this); // this will tell you what 'this' value is inside the each loop

    /*  ============================================================================
      Hero Scene
      ========================================================================= */
    var animationScene = new ScrollMagic.Scene({
      // define options for the scene
      // triggerElement: '.hero-content',
      triggerElement: this,
      // duration: '90%',
      triggerHook: 0.7,
      // reverse: false
    })
      // toggle class to hero01 and fading in using setClassToggle
      // .setClassToggle('.hero-content__wrap', 'fade-in')
      .setClassToggle(this, 'fade-in')
      // .addIndicators({
      //   name: 'content fade',
      //   colorTrigger: 'pink',
      //   colorStart: 'yellow',
      //   colorEnd: 'orange'
      // })
      .addTo(controller);
  }); // each loop fxn

  /*  ============================================================================
        Staff Pin Scenes
        ========================================================================= */
  // ================================= Scene 1 - which will pin the second section
  var pinScene01 = new ScrollMagic.Scene({
    triggerElement: '.staff__slide01',
    triggerHook: 0.25,
    duration: '100%',
  })
    .setPin('.staff__slide01 .staff__slide-pin-wrapper')
    // .addIndicators({
    //   name: 'pin 01',
    //   colorTrigger: 'pink',
    //   colorStart: 'yellow',
    //   colorEnd: 'orange'
    // })
    .addTo(controller);

  // ================================== Scene 2 - which will pin the third section
  var pinScene02 = new ScrollMagic.Scene({
    // want it to pin both sections at the same time
    triggerElement: '.staff__slide01',
    triggerHook: 0.25,
    duration: '200%',
  })
    .setPin('.staff__slide02 .staff__slide-pin-wrapper')
    .addTo(controller);

  // ================================== Scene 3 - which will pin the fourth section
  var pinScene03 = new ScrollMagic.Scene({
    triggerElement: '.staff__slide02',
    triggerHook: 0.25,
    duration: '200%',
  })
    .setPin('.staff__slide03 .staff__slide-pin-wrapper')
    .addTo(controller);

  // ================================== Scene 4 - which will pin the fifth section
  var pinScene04 = new ScrollMagic.Scene({
    triggerElement: '.staff__slide03',
    triggerHook: 0.25,
    duration: '200%',
  })
    .setPin('.staff__slide04 .staff__slide-pin-wrapper')
    .addTo(controller);

  // ================================== Scene 5 - which will pin the sixth section
  var pinScene05 = new ScrollMagic.Scene({
    // want it to pin both sections at the same time
    triggerElement: '.staff__slide04',
    triggerHook: 0.25,
    duration: '200%',
  })
    .setPin('.staff__slide05 .staff__slide-pin-wrapper')
    .addTo(controller);

  // ================================== Scene 6 - which will pin the seventh section
  var pinScene06 = new ScrollMagic.Scene({
    // want it to pin both sections at the same time
    triggerElement: '.staff__slide05',
    triggerHook: 0.25,
    duration: '200%',
  })
    .setPin('.staff__slide06 .staff__slide-pin-wrapper')
    .addTo(controller);

  // ================================== Scene 8 - which will pin the nineth section
  var pinScene07 = new ScrollMagic.Scene({
    // want it to pin both sections at the same time
    triggerElement: '.staff__slide06',
    triggerHook: 0.25,
    duration: '110%',
  })
    .setPin('.staff__slide07 .staff__slide-pin-wrapper')
    .addTo(controller);
}); // main page load fxn
